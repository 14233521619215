<template>
  <b-modal
    id="unicorn-filter-modal"
    modal-class="modal-style two-footer-btn filter-modal"
    ref="unicornFilterModal"
  >
    <template>
      <div class="modal-style-title">
        <p>篩選條件</p>
        <button class="clear-btn" @click="clearAll">
          <span class="icon-icn_clear"></span>
          全部清除
        </button>
      </div>

      <div class="filter-term">
        <p class="filter-term__title">
          各部會計畫 (複選)
        </p>
      </div>
      <template v-for="(ministry, index) in ministryArr">
        <SingleMinistry
          v-if="index > 0"
          :key="index"
          :ministry="ministry"
          v-model="projectFilter"
          :projectObj="projectObj"
        />
      </template>
      <template v-for="(ministry, index) in ministryArr">
        <SingleMinistry
          v-if="index == 0"
          :key="index"
          :ministry="ministry"
          v-model="projectFilter"
          :projectObj="projectObj"
        />
      </template>

      <!-- <div class="filter-term">
        <p class="filter-term__title">
          分類
        </p>
      </div>
      <ul class="row">
        <li
          class="col-lg-3 col-12"
          v-for="(classify, index) in classifyArr"
          :key="index"
        >
          <input
            class="custom-radio"
            :id="`styled-checkbox-${classify.text}`"
            type="radio"
            v-model="classifyFilter"
            :value="classify.value"
          />
          <label
            :for="`styled-checkbox-${classify.text}`"
            class="transparent-checkbox"
          >
            {{ classify.text }}
          </label>
        </li>
      </ul> -->

      <div class="filter-term">
        <p class="filter-term__title">
          團隊成立年份
        </p>
      </div>
      <div class="date-pick-area">
        <DatePicker
          class="custom-datepicker"
          ref="datePicker"
          :format="'YYYY'"
          placeholder="開始年份"
          v-model="startTime"
          type="year"
          valueType="YYYY/MM/DD"
          :editable="false"
          :disabled-date="endDate"
        >
          <template v-slot:icon-calendar>
            <img src="~@/assets/img/svg/icon_calendar.svg" alt="合作金庫首頁" />
          </template>
        </DatePicker>
        <span class="tilde">~</span>
        <DatePicker
          class="custom-datepicker"
          ref="datePicker"
          :format="'YYYY'"
          placeholder="截止年份"
          v-model="endTime"
          type="year"
          valueType="YYYY/MM/DD"
          :editable="false"
          :disabled-date="startDate"
        >
          <template v-slot:icon-calendar>
            <img src="~@/assets/img/svg/icon_calendar.svg" alt="合作金庫首頁" />
          </template>
        </DatePicker>
      </div>
    </template>

    <template v-slot:modal-footer>
      <button
        class="transparent-btn-black order-2  order-lg-1"
        @click="$bvModal.hide(`unicorn-filter-modal`)"
      >
        關閉
      </button>
      <button class="custom-btn order-lg-2 order-1" @click="submit">
        依篩選條件搜尋
      </button>
    </template>
  </b-modal>
</template>

<script>
const moment = require('moment');
import _ from 'lodash';
import SingleMinistry from './SingleMinistry';

export default {
  components: {
    SingleMinistry
  },
  props: {
    projectObj: {
      required: true,
      type: Object
    },
    ministryArr: {
      required: true,
      type: Array
    },
    companyYearRange: {
      required: true,
      type: Object
    }
  },

  data() {
    return {
      // projectObj: {},
      // ministryArr: [],
      classifyArr: [
        {
          value: 'type',
          text: '產業別'
        }
        // {
        //   value: 'apply',
        //   text: '產品應用'
        // }
        // {
        //   value: 'tech',
        //   text: '核心技術'
        // }
      ],
      projectFilter: [],
      classifyFilter: 'type',
      startTime: '',
      endTime: ''
    };
  },
  // watch: {
  //   companyData: {
  //     handler() {
  //       this.setMinistryArr(this.setProjectArr);
  //     },
  //     deep: true
  //   }
  // },
  created() {
    // this.setMinistryArr(this.setProjectArr);
  },
  methods: {
    startDate(date) {
      return (
        moment(date, 'YYYY/MM/DD') < moment(this.startTime, 'YYYY/MM/DD') ||
        moment(date, 'YYYY/MM/DD') >
          moment(this.companyYearRange.yearMax, 'YYYY') || // 最大創立年份
        moment(date, 'YYYY/MM/DD') <
          moment(this.companyYearRange.yearMin, 'YYYY') // 最小創立年份
      );
    },
    endDate(date) {
      return (
        moment(date, 'YYYY/MM/DD') > moment(this.endTime, 'YYYY/MM/DD') ||
        moment(date, 'YYYY/MM/DD') >
          moment(this.companyYearRange.yearMax, 'YYYY') || // 最大創立年份
        moment(date, 'YYYY/MM/DD') <
          moment(this.companyYearRange.yearMin, 'YYYY') // 最小創立年份
      );
    },
    submit() {
      let loader = this.$loading.show();
      setTimeout(() => {
        if (this.startTime || this.endTime) {
          if (this.startTime && this.endTime) {
            this.$emit('filterUnicorn', {
              project: this.projectFilter,
              classify: this.classifyFilter,
              startTime: this.startTime,
              endTime: this.endTime
            });
            this.$bvModal.hide(`unicorn-filter-modal`);
          } else {
            alert('起迄時間都需要選擇');
          }
        } else {
          this.$emit('filterUnicorn', {
            project: this.projectFilter,
            classify: this.classifyFilter,
            startTime: this.startTime,
            endTime: this.endTime
          });
          this.$bvModal.hide(`unicorn-filter-modal`);
        }
      }, 500);
      setTimeout(() => {
        loader.hide();
      }, 1000);
    },

    clearAll() {
      let loader = this.$loading.show();
      setTimeout(() => {
        this.projectFilter = [];
        this.classifyFilter = 'type';
        this.startTime = '';
        this.endTime = '';
        this.$emit('filterUnicorn', {
          project: this.projectFilter,
          classify: this.classifyFilter,
          startTime: this.startTime,
          endTime: this.endTime
        });
        this.$bvModal.hide(`unicorn-filter-modal`);
      }, 1000);
      setTimeout(() => {
        loader.hide();
      }, 1200);
    }

    // // 拿到所有的政府部門
    // setMinistryArr(callback) {
    //   let arr = [];
    //   this.companyData.map((item, index) => {
    //     if (_.indexOf(arr, item.ministry) == -1 && item.ministry !== '') {
    //       arr.push(item.ministry);
    //     }
    //   });
    //   this.ministryArr = arr;
    //   callback();
    // },

    // // 拿到於同個政府部門下的計畫
    // setProjectArr() {
    //   let obj = {};

    //   // 用剛剛拿到的政府部門
    //   this.ministryArr.map((item, index) => {
    //     obj[item] = [];
    //   });

    //   this.companyData.map((item, index) => {
    //     if (
    //       _.indexOf(obj[item.ministry], item.project_name) == -1 &&
    //       item.ministry !== ''
    //     ) {
    //       obj[item.ministry].push(item.project_name);
    //     }
    //   });
    //   this.projectObj = obj;
    // }
  }
};
</script>

<style lang="scss" scoped></style>
