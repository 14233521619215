<template>
  <div class="single-ministry">
    <div
      @click="isCollapseShow = !isCollapseShow"
      class="single-ministry__title-area"
    >
      <div class="login-status d-flex">
        <div @click.stop>
          <input
            class="custom-checkbox"
            :id="`styled-checkbox-${ministry}`"
            type="checkbox"
            v-model="allSelect"
          />
          <label :for="`styled-checkbox-${ministry}`"></label>
        </div>
      </div>
      <h5 class="single-ministry__title">{{ ministry }}</h5>

      <i class="fas fa-chevron-down" :class="{ active: isCollapseShow }"></i>
    </div>
    <b-collapse v-model="isCollapseShow">
      <div class="collapse-in row">
        <li
          class="col-lg-3 col-12"
          v-for="(project, index) in projectArrForFilter"
          :key="index"
        >
          <input
            class="custom-checkbox-rect"
            :id="`styled-checkbox-${project.value}`"
            type="checkbox"
            v-model="projectFilter"
            :value="project.value"
          />
          <label
            :for="`styled-checkbox-${project.value}`"
            class="transparent-checkbox"
          >
            {{ project.name }}
          </label>
        </li>
      </div>
    </b-collapse>
  </div>
</template>

<script>
const moment = require('moment');

import _ from 'lodash';

export default {
  props: {
    ministry: {
      required: true
    },
    value: {
      required: true
    },
    projectObj: {
      required: true
    }
  },

  data() {
    return {
      theMinistryData: [],
      projectArr: [],
      //   在這個部門下 被選到的篩選計畫
      projectFilterInThisMinistry: [],
      isCollapseShow: false
      // allSelect: true
    };
  },
  created() {
    this.setProjectArr();
    this.setProjectFilterInThisMinistry();
  },
  methods: {
    // 拿到隸屬於該部門下的計畫
    setProjectArr() {
      let arr = [];
      for (let i in this.projectObj) {
        if (i == this.ministry) {
          this.projectArr = this.projectObj[i];
        }
      }
    },

    // 設定這個部會下 所有有選中的篩選計畫 在created時 設定回來
    setProjectFilterInThisMinistry() {
      this.projectFilterInThisMinistry = this.value.filter(item => {
        return this.projectArr.includes(item);
      });
    }
  },

  computed: {
    projectFilter: {
      get() {
        return this.value;
      },
      set(value) {
        let valueInThisComponent = value.filter(item => {
          return this.projectArr.includes(item);
        });
        this.projectFilterInThisMinistry = valueInThisComponent;
        this.$emit('input', value);
      }
    },
    allSelect: {
      get() {
        if (this.projectFilterInThisMinistry.length == this.projectArr.length) {
          return true;
        } else {
          return false;
        }
      },
      set(value) {
        if (value) {
          this.projectFilterInThisMinistry = this.projectArr;
          let arr = [...this.value, ...this.projectArr];

          let newArr = [...new Set(arr)];
          this.$emit('input', newArr);
        } else {
          let arr = this.value.filter(item => {
            return !this.projectArr.includes(item);
          });
          this.projectFilterInThisMinistry = [];
          this.$emit('input', arr);
        }
      }
    },
    projectArrForFilter() {
      return this.projectArr.map(item => {
        let projectName = item.split('@@');
        return { value: item, name: projectName[1] };
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
