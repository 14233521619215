import callApi from './base/api';
import callApiRagic from './base/apiRagic';
import authorization from './base/authorization';
// import fastapiAuthorization from './base/fastapiAuthorization';

export default {
  fetchData(cb, errCb) {
    const config = {
      data: {
        PerPage: 9999,
        CurrentPage: 1,
        CompanyCode: '',
        CompanyName: '',
        ProjectName: '',
        Ministry: ''
      },
      ...authorization()
    };
    return callApi({
      url: 'EventData/EventDataList',
      method: 'post',
      cb,
      errCb,
      config
    });
  },
  fetchRagicEventData(cb, errCb) {
    const config = {
      params: {
        str_ragic_url: process.env.VUE_APP_API_RAGIC_URL + 'forms/2',
        token: process.env.VUE_APP_TOKEN
      }
    };
    return callApiRagic({
      url: 'api/getdashboarddata',
      method: 'get',
      cb,
      errCb,
      config
    });
  },
  fetchRagicCompanyData(cb, errCb) {
    const config = {
      params: {
        str_ragic_url: process.env.VUE_APP_API_RAGIC_URL + '-0503/15',
        token: process.env.VUE_APP_TOKEN
      }
    };
    return callApiRagic({
      url: 'api/getdashboarddata',
      method: 'get',
      cb,
      errCb,
      config
    });
  },
  fetchRagicFundraisingData(cb, errCb) {
    const config = {
      params: {
        str_ragic_url: process.env.VUE_APP_API_RAGIC_URL + '-0503/20',
        token: process.env.VUE_APP_TOKEN
      }
    };
    return callApiRagic({
      url: 'api/getdashboarddata',
      method: 'get',
      cb,
      errCb,
      config
    });
  }
};
