<template>
  <div class="main-content" v-if="dataLoaded">
    <div class="unicorn">
      <h1 class="page-title">
        預見台灣新創獨角獸
      </h1>

      <section class="white-box">
        <div class="row fundraise-accumulate">
          <div class="col-lg-6 col-12 flex-center one-area">
            <span class="fundraise-accumulate__title">新創團隊數</span>
            <div class="flex-center stores">
              <span class="big-text store-num">
                <AnimatedNumber
                  :value="bigInfo.companyNum"
                  :formatValue="formatToCompanyNum"
                  :duration="3000"
                  class="num-area text-center"
                />
              </span>
              <span class="unit">
                家
              </span>
            </div>
          </div>
          <div class="the-line"></div>
          <div class="col-lg-6 col-12 flex-center one-area">
            <span class="fundraise-accumulate__title">總募資額</span>
            <span class="price flex-center">
              $
              <span class="big-text">
                <AnimatedNumber
                  :value="bigInfo.totalFund"
                  :formatValue="formatToPrice"
                  :duration="3000"
                  class="num-area text-center"
                />
              </span>
              億
            </span>
            <span class="usd">新台幣</span>
          </div>
        </div>
      </section>

      <section class="white-box">
        <div class="chart-title" id="map-distribute">
          團隊募資與地區分佈
        </div>
        <div class="row map-staging-area">
          <div class="col-lg-6 col-12 one-chart-area">
            <div class="fund-raise-distribute-area">
              <div class="fund-raise-distribute-area__title">
                <span class="green-title">團隊所在地區（境內團隊家數）</span>
                <span class="sample-num">n={{ mapSampleNum }}</span>
              </div>
              <figure>
                <v-chart
                  :options="map"
                  ref="map"
                  autoresize
                  @click="handleSaveComapnyArray"
                />
              </figure>
            </div>
          </div>

          <div class="col-lg-6 col-12 one-chart-area">
            <h5 class="chart-title-area">
              <span class="green-title">團隊募資階段（家數）</span>
              <span class="sample-num">n={{ fundraisingSampleNum }}</span>
            </h5>

            <figure>
              <v-chart
                :options="horizonBarChart"
                ref="map"
                autoresize
                @click="handleSaveComapnyArray"
              />
            </figure>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-lg-6 col-12">
            <div class="">
              <h5 class="chart-title-area">
                <span class="green-title">團隊成立年份（家）</span>
                <span class="sample-num">
                  n={{ timeDistributionSampleNum }}
                </span>
              </h5>
              <figure>
                <v-chart
                  :options="establishedTimeChart"
                  autoresize
                  @click="handleSaveComapnyArray"
                />
              </figure>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="">
              <h5 class="chart-title-area">
                <span class="green-title">團隊應用領域分布（%）</span>
                <span class="sample-num">n={{ popularIndustrySampleNum }}</span>
              </h5>

              <figure class="margin-top-20">
                <div class="amchart" ref="hotPieChartdiv"></div>
              </figure>
            </div>
          </div>
        </div>
      </section>

      <section class="white-box plan-innovation" id="popular-industry">
        <div class="row">
          <div class="col-12">
            <div class="chart-title">
              熱門產業募資概況
              <!-- 計畫年度成果 -->
            </div>
          </div>
          <div class=" col-12 one-chart-area mb-5">
            <div class="tree-map-area">
              <div class="tree-map-area__title">
                團隊技術應用分佈 - TOP 20 領域（家）
              </div>
              <div class="tree-map-area__subtitle">
                n={{ industryTeamSampleNum }}
              </div>
            </div>
            <figure>
              <v-chart
                :options="numberChart"
                autoresize
                @click="handleSaveComapnyArray"
              />
            </figure>
          </div>
        </div>
      </section>

      <section class="white-box " id="annual-result">
        <div class="chart-title mb-4">
          計畫年度成果
        </div>
        <div class="row">
          <div class="col-lg-6 col-12 one-chart-area">
            <h5 class="chart-title-area">
              <span class="green-title">計畫年度成果</span>
              <span class="sample-num">n={{ assistTeamSampleNum }}</span>
            </h5>

            <figure class="margin-top-20">
              <v-chart
                :options="planChart"
                autoresize
                ref="planChart"
                @click="handleSaveComapnyArrayMinistry"
              />
            </figure>
          </div>
          <div class="col-lg-6 col-12 one-chart-area">
            <div class="fund-raise-distribute-area">
              <div class="fund-raise-distribute-area__title ">
                <p class="green-title">部會提供資料數（家次）</p>
                <span class="sample-num">n={{ stadiumSample }}</span>
              </div>

              <figure>
                <v-chart
                  :options="ministryNumChart"
                  autoresize
                  @click="handleSaveComapnyArray"
                />
              </figure>
            </div>
          </div>
          <div class="col-12 one-chart-area mt-4">
            <h5 class="chart-title-area">
              <span class="green-title">團隊獲各計畫協助比例（家數）</span>
              <span class="sample-num">n={{ participatePlanSampleNum }}</span>
            </h5>

            <figure>
              <v-chart
                :options="participateChart"
                autoresize
                @click="handleSaveComapnyArray"
              />
            </figure>
          </div>

          <div class="col-12 one-chart-area mt-5">
            <div class="">
              <h5 class="chart-title-area">
                <span class="green-title">獲跨計畫協助團隊家數</span>
                <span class="sample-num">n={{ planAssistSampleNum }}</span>
              </h5>

              <figure class="margin-top-20">
                <div class="amchart" ref="planAssistColumChart"></div>
              </figure>
            </div>
          </div>
        </div>
      </section>
    </div>

    <UnicornFilterModal
      @filterUnicorn="filterUnicorn"
      :projectObj="projectObj"
      :ministryArr="ministryArr"
      :companyYearRange="companyYearRange"
    />
  </div>
</template>

<script>
import UnicornFilterModal from '@/components/Modal/UnicornFilterModal';
import _ from 'lodash';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4geodata_continentsLow from '@amcharts/amcharts4-geodata/continentsLow';
import * as am4plugins_timeline from '@amcharts/amcharts4/plugins/timeline';
import * as am4plugins_bullets from '@amcharts/amcharts4/plugins/bullets';
import am4themes_moonrisekingdom from '@amcharts/amcharts4/themes/moonrisekingdom.js';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import unicornApi from '@/api/unicorn';

const moment = require('moment');
const ECharts = () => import('@/plugins/echart');

export default {
  name: 'Unicorn',
  metaInfo: {
    meta: [
      {
        property: 'og:title',
        content: '預見台灣新創獨角獸',
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: '預見台灣新創獨角獸',
        vmid: 'og:description'
      }
    ]
  },
  components: {
    UnicornFilterModal,
    'v-chart': ECharts
  },

  data() {
    return {
      saveCompanyArray: [],
      companyDataOrigin: [],
      bigInfo: {
        totalFund: '',
        companyNum: ''
      },
      unicornInfo: {
        projectFilter: [],
        classifyFilter: 'type',
        startTime: '',
        endTime: ''
      },

      // 每張圖表樣本數
      mapSampleNum: 0,
      fundraisingSampleNum: 0,
      assistTeamSampleNum: 0,
      popularIndustrySampleNum: 0,
      teamfundraisingSampleNum: 0,
      industryTeamSampleNum: 0,
      timeDistributionSampleNum: 0,
      participatePlanSampleNum: 0,
      planAssistSampleNum: 0,

      // 行業別資本額圖表
      moneyChart: {
        tooltip: {
          formatter: function(info) {
            var name = info.name;
            var value = info.value;
            function toCurrency(value) {
              var parts = value.toString().split('.');
              parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              return parts.join('.');
            }

            let currency = toCurrency(value);

            return name + '<br/>' + 'NT$' + currency;
          },
          extraCssText: 'z-index:500;'
        },

        series: [
          {
            name: '行業別（募資額）',
            type: 'treemap',
            width: '100%',
            height: '100%',
            roam: false,
            breadcrumb: {
              show: false
            },

            label: {
              normal: {
                position: 'insideTopLeft',
                formatter: function(params) {
                  function toCurrency(value) {
                    var parts = value.toString().split('.');
                    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    return parts.join('.');
                  }

                  let money = toCurrency((params.value / 1000000).toFixed(2));

                  var arr = [
                    '{name|' + params.name + '}',
                    '{hr|}',
                    '{money|' + '$' + money + '}'
                  ];
                  return arr.join('\n');
                },
                rich: {
                  name: {
                    fontSize: 20,
                    color: '#000'
                  },
                  hr: {
                    width: '100%',
                    borderWidth: 0.5,
                    height: 0,
                    lineHeight: 10
                  },
                  money: {
                    fontSize: 20,
                    color: '#000'
                  }
                }
              }
            },

            levels: [
              {},
              {
                color: ['#BFEBEC', '#9adadb', '#40C4C5', '#00B1B2'],
                colorMappingBy: 'value',
                itemStyle: {
                  gapWidth: 1
                }
              }
            ],

            data: [
              {
                name: '行業別（募資額）', // First tree
                value: null,

                children: []
              }
            ]
          }
        ],

        animationDuration: 2000
      },

      // 行業別家數圖表
      numberChart: {
        tooltip: {
          formatter: function(info) {
            function toCurrency(value) {
              var parts = value.toString().split('.');
              parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              return parts.join('.');
            }
            var name = info.name;
            var value = info.value;

            let storeNum = toCurrency(value);
            return name + '<br/>' + storeNum + '家';
          },
          extraCssText: 'z-index:500;'
        },

        series: [
          {
            name: '行業別（家數）',
            type: 'treemap',
            width: '100%',
            height: '100%',
            roam: false,
            breadcrumb: {
              show: false
            },

            label: {
              normal: {
                position: 'insideTopLeft',
                formatter: function(params) {
                  function toCurrency(value) {
                    var parts = value.toString().split('.');
                    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    return parts.join('.');
                  }
                  let number = toCurrency(params.value);

                  var arr = [
                    '{name|' + params.name + '}',
                    '{hr|}',
                    '{number|' + number + '}'
                  ];

                  return arr.join('\n');
                },
                rich: {
                  name: {
                    fontSize: 20,
                    color: '#000'
                  },
                  hr: {
                    width: '100%',
                    borderWidth: 0.5,
                    height: 0,
                    lineHeight: 10
                  },
                  number: {
                    fontSize: 20,
                    color: '#000'
                  }
                }
              }
            },

            levels: [
              {},
              {
                color: ['#BFEBEC', '#9adadb', '#40C4C5', '#00B1B2'],
                colorMappingBy: 'value',
                itemStyle: {
                  gapWidth: 1
                }
              }
            ],

            data: [
              {
                name: '行業別（家數）', // First tree
                value: null,

                children: []
              }
            ]
          }
        ],

        animationDuration: 2000
      },

      // 部會提供團隊募資概況 圖表
      planChart: {
        title: {
          subtext: '泡泡大小為募資金額',
          left: 'right',
          subtextStyle: {
            color: '#464646',
            fontSize: '14'
          }
        },
        tooltip: {
          formatter: function(val) {
            function toCurrency(value) {
              var parts = value.toString().split('.');
              parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              return parts.join('.');
            }
            let teamNum = toCurrency(val.value[1]);
            let fund = toCurrency(val.value[2]);
            if (val.value[4] && val.value[5]) {
              return (
                val.value[3] +
                '</br>' +
                '年份:' +
                val.value[4] +
                '~' +
                val.value[5] +
                '</br>' +
                '團隊家數:' +
                teamNum +
                '</br>' +
                '團隊累計募資額:' +
                fund
              );
            } else {
              return (
                val.value[3] +
                '</br>' +
                '團隊家數:' +
                teamNum +
                '</br>' +
                '團隊累計募資額:' +
                fund
              );
            }
          },
          extraCssText: 'z-index:500;'
        },

        grid: {
          width: '75%',
          height: '69%',
          left: '12%'
        },
        xAxis: {
          name: '團隊累計募資額(百萬新台幣元)',
          nameLocation: 'center',

          nameTextStyle: {
            lineHeight: 90,
            fontSize: '16'
          },
          axisLabel: {
            formatter: function(value) {
              function toCurrency(value) {
                var parts = value.toString().split('.');
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                return parts.join('.');
              }
              return toCurrency(value / 1000000);
            },
            rotate: -40
          },
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        yAxis: {
          name: '團隊家數',
          nameRotate: 0.1,
          nameGap: 20,
          nameTextStyle: {
            fontSize: '16'
          },
          axisLabel: {
            formatter: function(value) {
              function toCurrency(value) {
                var parts = value.toString().split('.');
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                return parts.join('.');
              }
              return toCurrency(value);
            }
          },
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          },
          scale: true
        },
        series: []
      },

      // 團隊所在地區（境內團隊家數）
      map: {
        backgroundColor: '#BDE3FD',

        title: {
          // text: '全台灣新創獨角獸公司家數',
          // subtext: '工研院統計資料',
          left: 'center',
          textStyle: {
            color: '#003535'
          }
        },

        tooltip: {
          trigger: 'item',
          showDelay: 0,
          transitionDuration: 0.2,
          extraCssText: 'z-index:500;'
        },

        legend: {
          orient: 'vertical',
          y: 'bottom',
          x: 'right',
          data: ['新創公司家數'],
          textStyle: {
            color: '#000'
          }
        },
        geo: {
          map: 'taiwan',
          aspectScale: 1,
          zoom: 1.2,
          center: [120.8098, 23.79108],
          label: {
            emphasis: {
              show: true
            }
          },
          itemStyle: {
            normal: {
              areaColor: '#fff',
              borderColor: '#111'
            },
            emphasis: {
              areaColor: '#00C2C3'
            }
          }
        },
        series: []
      },

      // 台灣各縣市資料
      geoCoordMap: {
        台北市: [121.5598, 25.09108],
        新北市: [121.6739, 24.91571],
        台中市: [120.9417, 24.23321],
        高雄市: [120.666, 23.01087],
        桃園市: [121.2168, 24.93759],
        台南市: [120.2513, 23.1417],
        彰化縣: [120.4818, 23.99297],
        屏東縣: [120.62, 22.54951],
        雲林縣: [120.3897, 23.75585],
        苗栗縣: [120.9417, 24.48927],
        嘉義縣: [120.574, 23.45889],
        新竹縣: [121.1252, 24.70328],
        南投縣: [120.9876, 23.83876],
        宜蘭縣: [121.7195, 24.69295],
        新竹市: [120.9647, 24.80395],
        基隆市: [121.7081, 25.10898],
        花蓮縣: [121.3542, 23.7569],
        嘉義市: [120.4473, 23.47545],
        台東縣: [120.9876, 22.98461],
        金門縣: [118.3186, 24.43679],
        澎湖縣: [119.6151, 23.56548],
        連江縣: [119.5397, 26.19737]
      },

      // 團隊募資階段 圖表
      horizonBarChart: {
        title: {},
        tooltip: {
          extraCssText: 'z-index:500;',
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function(params) {
            function toCurrency(value) {
              var parts = value.toString().split('.');
              parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              return parts.join('.');
            }
            return (
              params[0].name +
              '<br/>' +
              '募資金額' +
              ': ' +
              'NT $' +
              toCurrency(params[0].data.money) +
              '<br/>' +
              '公司家數: ' +
              params[0].data.companyNum
            );
          }
        },

        grid: {
          width: '75%',
          height: '74%',
          left: '20%'
          // containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
          name: '新創公司家數',
          nameLocation: 'center',

          nameTextStyle: {
            lineHeight: 50,
            fontSize: '16'
          },
          axisLabel: {
            formatter: function(value) {
              function toCurrency(value) {
                var parts = value.toString().split('.');
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                return parts.join('.');
              }

              return toCurrency(value);
            },
            rotate: 0
          },
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        yAxis: {
          type: 'category',
          data: []
        },
        series: [
          {
            name: '',
            type: 'bar',
            data: []
          }
        ]
      },

      // 公司成立時間（家） 圖表
      establishedTimeChart: {
        color: ['#00B1B2'],
        tooltip: {
          extraCssText: 'z-index:500;',
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },

        xAxis: [
          {
            type: 'category',
            data: [],
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            minInterval: 1
          }
        ],
        dataZoom: [
          {
            show: true,
            start: 0,
            end: 100,
            height: 15,
            bottom: 0
          },
          {
            type: 'inside',
            start: 94,
            end: 100,
            zoomLock: true
          }
        ],
        series: [
          {
            name: '家數',
            type: 'bar',
            barWidth: '60%',
            data: []
          }
        ]
      },

      // 部會-公司數量
      ministryNumChart: {
        color: ['#00B1B2'],
        tooltip: {
          extraCssText: 'z-index:500;',
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: function(params) {
            function toCurrency(value) {
              var parts = value.toString().split('.');
              parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              return parts.join('.');
            }
            var name = params[0].name;
            var value = params[0].value;
            let bussinessStatus = params[0].data.bussinessStatus;
            let bussinessStatusFinal = '';

            // 營業狀態
            for (let i in bussinessStatus) {
              if (toCurrency(bussinessStatus[i].num) === '0') {
                continue;
              }
              bussinessStatusFinal +=
                bussinessStatus[i].bussiness +
                ' : ' +
                toCurrency(bussinessStatus[i].num) +
                '家' +
                '</br>';
            }
            return (
              name +
              ' : ' +
              toCurrency(value) +
              '家' +
              '</br>' +
              bussinessStatusFinal
            );
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            // name: '部會',
            type: 'category',
            data: [],
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: {
              interval: 0, //横轴信息全部显示
              rotate: -30 //60度角倾斜显示
              // formatter: function(val) {
              //   return val.split('').join('\n');
              // } //横轴信息文字竖直显示
            }
          }
        ],
        yAxis: [
          {
            name: '團隊家次',
            type: 'value',
            data: [],
            minInterval: 1
          }
        ],
        dataZoom: [
          {
            show: true,
            start: 0,
            end: 100,
            height: 15,
            bottom: 0
          },
          {
            type: 'inside',
            start: 0,
            end: 100,
            zoomLock: true
          }
        ],
        series: [
          {
            name: '家數',
            type: 'bar',
            barWidth: '60%',
            data: []
          }
        ]
      },

      // 團隊獲各計畫協助比例（家數）
      participateChart: {
        tooltip: {
          extraCssText: 'z-index:500;',
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            label: {
              show: true
            }
          },
          formatter: function(params) {
            function toCurrency(value) {
              var parts = value.toString().split('.');
              parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              return parts.join('.');
            }
            return (
              params[0].name +
              '<br/>' +
              '團隊家數: ' +
              toCurrency(params[0].value) +
              '<br/>' +
              '計畫所佔比例: ' +
              params[1].value +
              '%'
            );
          }
        },
        calculable: true,
        grid: {
          top: '2%',
          left: '3%',
          right: '10%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            name: '計畫名稱',
            nameGap: 130,
            nameLocation: 'center',
            nameTextStyle: {
              lineHeight: 35,
              fontSize: '16'
            },
            data: [], //names
            axisLabel: {
              interval: 0, //横轴信息全部显示
              rotate: 30 //30度角倾斜显示
              // formatter:function(val){
              // return val.split("").join("\n");} //横轴信息文字竖直显示
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              // formatter: function(a) {
              //   a = +a;
              //   return isFinite(a) ? echarts.format.addCommas(+a / 1000) : '';
              // }
            }
          },
          {
            minInterval: 6,
            type: 'value',
            axisLabel: {
              formatter: '{value} %'
            }
          }
        ],
        dataZoom: [
          {
            show: true,
            start: 0,
            end: 100,
            height: 15,
            bottom: 0
            // zoomLock: true
          },
          {
            type: 'inside',
            start: 94,
            end: 100,
            zoomLock: true
          },
          {
            show: true,
            yAxisIndex: 0,
            filterMode: 'empty',
            width: 30,
            height: '80%',
            showDataShadow: false,
            left: '93%'
            // zoomLock: true
          }
        ],
        series: [
          {
            name: '團隊獲各計畫協助比例',
            roam: false,
            type: 'bar',
            data: [] //data
          },
          {
            name: '參與百分比',
            type: 'line',
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                color: '#00FF00',
                lineStyle: {
                  color: '#00FF00'
                }
              }
            },

            data: []
          }
        ]
      },
      tabIndex: 0,

      // ------------------
      ministryLogo: [
        { ministry: '經濟部', imgLink: require('@/assets/img/經濟部logo.png') },
        { ministry: '國發會', imgLink: require('@/assets/img/國發會logo.png') },
        { ministry: '科技部', imgLink: require('@/assets/img/科技部logo.png') },
        {
          ministry: '國發基金',
          imgLink: require('@/assets/img/國發基金logo.png')
        },
        { ministry: '教育部', imgLink: require('@/assets/img/教育部logo.png') },
        { ministry: '金管會', imgLink: require('@/assets/img/金管會logo.png') },
        { ministry: '文化部', imgLink: require('@/assets/img/文化部logo.png') },
        { ministry: '其它', imgLink: require('@/assets/img/其它.png') }
      ],
      stadiumMenu: '公司數量',
      stadiumSample: 0,
      ministryArr: [],
      projectObj: {},
      windowWidth: window.innerWidth,
      globalMapData: [],
      globalSample: ''
    };
  },

  computed: {
    ...mapGetters('user', ['isLogin']),
    ...mapGetters('Unicorn', ['getCompanyDataInSaveCompanyArray']),

    companyYearRange() {
      let yearRange = {
        yearMin: '',
        yearMax: ''
      };
      this.companyData.forEach((item, index) => {
        if (item.established_time && !isNaN(item.established_time)) {
          yearRange.yearMin =
            yearRange.yearMin === '' ||
            yearRange.yearMin > item.established_time
              ? item.established_time
              : yearRange.yearMin;
          yearRange.yearMax =
            yearRange.yearMax === '' ||
            yearRange.yearMax < item.established_time
              ? item.established_time
              : yearRange.yearMax;
        }
      });
      return yearRange;
    },
    companyData() {
      return this.companyDataOrigin.map(item => {
        return {
          ...item,
          forFilter: item.ministry + '@@' + item.project_name
        };
      });
    },
    dataLoaded() {
      return this.companyData.length > 0;
    },
    allFundTypeData() {
      return this.fundData.map(item => {
        return { type: item.type, money: item.money };
      });
    },
    projectFilterLength() {
      let arr = [];
      for (let i in this.projectInfo) {
        if (this.projectInfo[i] && this.projectInfo[i].length !== 0) {
          arr.push(this.projectInfo[i]);
        }
      }
      if (this.projectInfo.endTime) {
        return arr.length - 1;
      } else {
        return arr.length;
      }
    },
    fundFilterLength() {
      let arr = [];
      for (let i in this.fundInfo) {
        if (this.fundInfo[i] && this.fundInfo[i].length !== 0) {
          arr.push(this.fundInfo[i]);
        }
      }
      if (this.fundInfo.endTime) {
        return arr.length - 1;
      } else {
        return arr.length;
      }
    }
  },

  watch: {
    saveCompanyArray: {
      deep: true,
      handler() {
        let arr = [];
        this.saveCompanyArray.forEach(company => {
          let tempData = this.companyData.find(item => {
            return item.company == company;
          });

          if (tempData) {
            arr.push(tempData);
          }
        });
        this.setCompanyDataInSaveCompanyArray(arr);
      }
    },
    dataLoaded: {
      handler() {
        if (this.dataLoaded) {
          this.toggleIsDataLoaded();
        }
      },
      immediate: true
    },
    tabIndex() {
      this.$refs.cloudType.update();
      this.$refs.cloudApply.update();
      this.$refs.cloudTech.update();
    },
    projectInfo: {
      handler() {
        this.setFundData();
      },
      deep: true
    },
    companyData: {
      deep: true,
      handler() {
        this.setTotalAmount();
        this.setFundData();
        this.setAnnualFunding();
        this.setMap();
        this.setFundRasingStage();
        this.setparticipateNum();
        this.setEstablishedTimeChart();

        // ---------------------

        this.setStadiumData();

        // --------給modal的資料
        this.setMinistryArr(this.setProjectArr);
        this.$nextTick(() => {
          this.setHotPieChart();
          this.setPlanAssistColumChart();
        });

        // this.$nextTick(() => {
        //   // this.setTextCloud();
        //   this.globalMapChart();
        // });
      }
      // immediate: true
    },
    unicornInfo: {
      handler() {
        this.setTotalAmount();
        this.setFundData();
        // 計畫年度成果的表需要refresh才會刷新
        this.setAnnualFunding();
        this.$refs.planChart.refresh();
        this.setMap();
        this.setFundRasingStage();
        this.setparticipateNum();
        this.setEstablishedTimeChart();

        // ---------------
        this.setStadiumData();
        this.setHotPieChart();
        this.setPlanAssistColumChart();
      },
      deep: true
    },
    fundInfo: {
      handler() {
        this.setAnnualFunding();
      },
      deep: true
    }
  },

  mounted() {
    window.addEventListener('resize', this.onResize);
  },

  created() {
    const ragicMode = true;
    // if (!ragicMode) {
    //   unicornApi.fetchData(res => {
    //     this.companyDataOrigin = res;

    //     let nameArr = this.companyDataOrigin.map(item => item.company);
    //     let noRepeatArr = [...new Set(nameArr)];

    //     // this.setAllCompanyArray(noRepeatArr);
    //     let arr = [];
    //     noRepeatArr.forEach(company => {
    //       let tempData = this.companyData.find(item => {
    //         return item.company == company;
    //       });

    //       if (tempData) {
    //         arr.push(tempData);
    //       }
    //     });
    //     this.setCompanyDataAllCompanyArray(arr);
    //   });
    // }

    if (ragicMode) {
      unicornApi.fetchRagicCompanyData(res => {
        const companyData = res;
        unicornApi.fetchRagicEventData(res => {
          const eventData = res;
          unicornApi.fetchRagicFundraisingData(res => {
            const fundraisingData = res;

            const resultData = [];
            let dataCompany = {};
            for (let index in companyData) {
              let companyName = companyData[index]['name'];

              dataCompany[companyName] = companyData[index];
            }

            // 團隊的計畫內容跟公司資料結合
            let eventDataCompany = {};
            for (let index in eventData) {
              let companyName = eventData[index]['公司名稱'];

              if (!_.get(eventDataCompany, companyName)) {
                eventDataCompany[companyName] = [];
              }

              eventData[index]['apply'] = dataCompany[companyName]
                ? dataCompany[companyName]['sector']
                : [];
              eventData[index]['bussiness_status'] = dataCompany[companyName]
                ? dataCompany[companyName]['status']
                : '';
              eventData[index]['city'] = dataCompany[companyName]
                ? dataCompany[companyName]['county']
                : '';
              eventData[index]['company_code'] = dataCompany[companyName]
                ? dataCompany[companyName]['id']
                : '';
              eventData[index]['established_time'] = dataCompany[companyName]
                ? dataCompany[companyName]['since']
                : '';
              eventData[index]['tech'] = dataCompany[companyName]
                ? dataCompany[companyName]['tags']
                : [];
              eventData[index]['type'] = dataCompany[companyName]
                ? dataCompany[companyName]['category']
                : [];
              eventData[index]['url'] = dataCompany[companyName]
                ? dataCompany[companyName]['website']
                : '';

              eventDataCompany[companyName].push(eventData[index]);
            }

            let fundraisingDataCompany = {};
            for (let index in fundraisingData) {
              let companyName = fundraisingData[index]['nameZh'];

              if (!_.get(fundraisingDataCompany, companyName)) {
                fundraisingDataCompany[companyName] = [];
              }
              fundraisingDataCompany[companyName].push(fundraisingData[index]);
            }

            // 團隊的計畫內容與募資階段結合
            for (let evenCompanyNameIndex in eventDataCompany) {
              for (let i in eventDataCompany[evenCompanyNameIndex]) {
                if (fundraisingDataCompany[evenCompanyNameIndex]) {
                  for (let y in fundraisingDataCompany[evenCompanyNameIndex]) {
                    let tempArr = [];
                    tempArr['apply'] = eventDataCompany[evenCompanyNameIndex][
                      i
                    ]['apply'].join(', ');
                    tempArr['bussiness_status'] =
                      eventDataCompany[evenCompanyNameIndex][i][
                        'bussiness_status'
                      ];
                    tempArr['city'] =
                      eventDataCompany[evenCompanyNameIndex][i]['city'];
                    tempArr['company'] = evenCompanyNameIndex;
                    tempArr['company_code'] =
                      eventDataCompany[evenCompanyNameIndex][i]['company_code'];
                    tempArr['established_time'] =
                      eventDataCompany[evenCompanyNameIndex][i][
                        'established_time'
                      ];
                    tempArr['fundraising'] =
                      fundraisingDataCompany[evenCompanyNameIndex][y][
                        'fundTotal_NTD'
                      ];
                    tempArr['fundraising_stage'] =
                      fundraisingDataCompany[evenCompanyNameIndex][y][
                        'fundStatus'
                      ];
                    tempArr['ministry'] =
                      eventDataCompany[evenCompanyNameIndex][i]['主管部會'];
                    tempArr['project_name'] =
                      eventDataCompany[evenCompanyNameIndex][i]['計畫名稱'];
                    tempArr['tech'] = eventDataCompany[evenCompanyNameIndex][i][
                      'tech'
                    ].join(', ');
                    tempArr['type'] = eventDataCompany[evenCompanyNameIndex][i][
                      'type'
                    ].join(', ');
                    tempArr['url'] =
                      eventDataCompany[evenCompanyNameIndex][i]['url'];
                    resultData.push(tempArr);
                  }
                } else {
                  let tempArr = [];
                  tempArr['apply'] = eventDataCompany[evenCompanyNameIndex][i][
                    'apply'
                  ].join(', ');
                  tempArr['bussiness_status'] =
                    eventDataCompany[evenCompanyNameIndex][i][
                      'bussiness_status'
                    ];
                  tempArr['city'] =
                    eventDataCompany[evenCompanyNameIndex][i]['city'];
                  tempArr['company'] = evenCompanyNameIndex;
                  tempArr['company_code'] =
                    eventDataCompany[evenCompanyNameIndex][i]['company_code'];
                  tempArr['established_time'] =
                    eventDataCompany[evenCompanyNameIndex][i][
                      'established_time'
                    ];
                  tempArr['fundraising'] = '';
                  tempArr['fundraising_stage'] = '';
                  tempArr['ministry'] =
                    eventDataCompany[evenCompanyNameIndex][i]['主管部會'];
                  tempArr['project_name'] =
                    eventDataCompany[evenCompanyNameIndex][i]['計畫名稱'];
                  tempArr['tech'] = eventDataCompany[evenCompanyNameIndex][i][
                    'tech'
                  ].join(', ');
                  tempArr['type'] = eventDataCompany[evenCompanyNameIndex][i][
                    'type'
                  ].join(', ');
                  tempArr['url'] =
                    eventDataCompany[evenCompanyNameIndex][i]['url'];
                  resultData.push(tempArr);
                }
              }
            }

            this.companyDataOrigin = resultData;

            let nameArr = this.companyDataOrigin.map(item => item.company);
            let noRepeatArr = [...new Set(nameArr)];

            // this.setAllCompanyArray(noRepeatArr);
            let arr = [];
            noRepeatArr.forEach(company => {
              let tempData = this.companyData.find(item => {
                return item.company == company;
              });

              if (tempData) {
                arr.push(tempData);
              }
            });
            this.setCompanyDataAllCompanyArray(arr);
          });
        });
      });
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    am4core.disposeAllCharts();
    this.toggleIsDataLoadedFalse();
  },

  methods: {
    ...mapActions('Unicorn', [
      'toggleIsDataLoaded',
      'toggleIsDataLoadedFalse',
      'toggleIsChartClickFalse'
    ]),
    ...mapMutations('Unicorn', [
      'setCompanyDataInSaveCompanyArray',
      'setCompanyDataAllCompanyArray'
    ]),

    // 圖表點擊後 點擊的東西有的公司名稱陣列 存進 saveCompanyArray
    handleSaveComapnyArray() {
      this.saveCompanyArray = arguments[0].data.nameArr;
      this.toggleIsChartClickFalse();
      this.$bvModal.show(`company-modal`);
    },

    // 圖表點擊後 點擊的東西有的公司名稱陣列 存進 saveCompanyArray (部會提供團隊募資概況)
    handleSaveComapnyArrayMinistry() {
      this.saveCompanyArray = arguments[0].data[6];
      this.toggleIsChartClickFalse();
      this.$bvModal.show(`company-modal`);
    },

    // 圖表點擊後 點擊的東西有的公司名稱陣列 存進 saveCompanyArray (熱門產業(文字雲))
    wordClickHandler(name, value, vm) {
      let data = vm.words.find(item => item.text == name);
      this.saveCompanyArray = data['公司'];
      this.toggleIsChartClickFalse();
      this.$bvModal.show(`company-modal`);
    },

    // 千位符
    toCurrency(value) {
      var parts = value.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return parts.join('.');
    },
    // 陣列累加
    reduceArr(arr) {
      return arr.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      });
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },

    // 部會提供資料數（家次）
    setStadiumData() {
      let vm = this;
      let companyData = this.companyData;

      // 要是計畫篩選有值
      if (this.unicornInfo.projectFilter.length !== 0) {
        let projectFilterArr = [];
        this.unicornInfo.projectFilter.map(item => {
          projectFilterArr.push(..._.filter(companyData, { forFilter: item }));
        });
        companyData = projectFilterArr;
      }

      // 要是有篩選時間
      if (this.unicornInfo.startTime || this.unicornInfo.endTime) {
        let timeArr = [];
        companyData.map((item, index) => {
          if (
            moment(this.unicornInfo.startTime, 'YYYY/MM/DD') <=
              moment(item.established_time, 'YYYY') &&
            moment(this.unicornInfo.endTime, 'YYYY/MM/DD') >=
              moment(item.established_time, 'YYYY')
          ) {
            timeArr.push(item);
          }
        });
        companyData = timeArr;
      }

      // 刪掉重複的物件 func
      function removeRepeatObj(arr) {
        let hashTable = {};
        return arr.filter(function(el) {
          let key = JSON.stringify(el);
          let match = Boolean(hashTable[key]);
          return match ? false : (hashTable[key] = true);
        });
      }
      // let deduped = dedup([{ a: 1 }, { a: 1 }, [1, 2], [1, 2]]);

      companyData = removeRepeatObj(companyData);

      // -----------

      // 部會陣列，去掉重複值
      let ministryArr = companyData.map(item => item.ministry);
      let ministrySet = Array.from(new Set(ministryArr)); // set: 找出重複的值
      // 刪掉部會是空值的
      for (let i = 0; i < ministrySet.length; i++) {
        if (ministrySet[i] == '') {
          ministrySet.splice(i, 1);
        }
      }

      let tempArr = ministrySet.map(item => {
        return {
          name: item,
          company: [],
          onBussiness: [],
          notSure: [],
          closed: [],
          survey: [],
          sum: 0,
          moneyArr: [],
          bussiness_status: [],
          bussiness_status_norepeat: [],
          bussinessType: []
        };
      });

      // 把符合部會的公司編號，裝進對應屬性是該部會的陣列裡
      // 單純只考慮公司編號欄位，沒有營業狀態
      for (let i = 0; i < tempArr.length; i++) {
        for (let j = 0; j < companyData.length; j++) {
          if (companyData[j].ministry == tempArr[i]['name']) {
            tempArr[i]['company'].push(companyData[j].company);

            // ---------
            // 把營業狀態重複的刪掉
            let statusArr = {
              company: companyData[j].company,
              bussiness_status: companyData[j].bussiness_status
                ? companyData[j].bussiness_status
                : '未登記'
            };

            tempArr[i]['bussiness_status'].push(statusArr);
            tempArr[i]['bussiness_status'] = removeRepeatObj(
              tempArr[i]['bussiness_status']
            );
          }
        }
      }

      // 公司屬性的陣列，去掉重複值
      for (let i = 0; i < tempArr.length; i++) {
        for (let j = 0; j < tempArr[i]['company'].length; j++) {
          tempArr[i]['company'] = Array.from(new Set(tempArr[i]['company']));
          if (tempArr[i]['company'][j] == '') {
            tempArr[i]['company'].splice(j, 1);
          }
        }
      }

      // 蒐集所有贊助金額
      let moneyTempArr = [];
      for (let i = 0; i < ministrySet.length; i++) {
        for (let j = 0; j < this.companyData.length; j++) {
          if (this.companyData[j].ministry == ministrySet[i]) {
            tempArr[i].moneyArr.push(parseInt(this.companyData[j].fundraising));
          }
        }
      }

      // 相加所有贊助金額
      for (let i = 0; i < tempArr.length; i++) {
        for (let j = 0; j < tempArr[i]['moneyArr'].length; j++) {
          tempArr[i]['moneyArr'] = tempArr[i]['moneyArr'].filter(
            el => !Number.isNaN(el)
          );

          tempArr[i].sum = tempArr[i]['moneyArr'].reduce(function(
            accumulator,
            currentValue
          ) {
            return accumulator + currentValue;
          },
          0);
        }
      }

      let bussinessTypeArr = companyData.map(item => item.bussiness_status);
      bussinessTypeArr = Array.from(new Set(bussinessTypeArr)); // set: 找出重複的值

      // 已被合併,營運中,解散,非營運中,預查中
      // 刪掉營業狀態是空值的
      // for (let i in bussinessTypeArr) {
      //   if (bussinessTypeArr[i] == null) {
      //     bussinessTypeArr.splice(i, 1);
      //   }
      // }
      bussinessTypeArr = bussinessTypeArr.map(el => {
        return {
          bussiness: el ? el : '未登記',
          // bussiness: el,
          num: 0
        };
      });

      // 最後取得不重複營業狀態
      for (let i in tempArr) {
        let bussiness_status = tempArr[i]['bussiness_status'];
        let company = tempArr[i]['company'];
        company.forEach(companyItem => {
          let found = bussiness_status.find(item => {
            return item.company == companyItem;
          });
          tempArr[i]['bussiness_status_norepeat'].push(found);
        });

        tempArr[i].bussinessType = _.cloneDeep(bussinessTypeArr);
      }

      for (let i in tempArr) {
        for (let j in tempArr[i]['bussinessType']) {
          for (let k in tempArr[i]['bussiness_status_norepeat']) {
            let bussinessType = tempArr[i]['bussinessType'];
            let status =
              tempArr[i]['bussiness_status_norepeat'][k]['bussiness_status'];
            if (bussinessType[j]['bussiness'] == status) {
              bussinessType[j]['num']++;
            }
            // if (status == '營業中') {
            //   tempArr[i]['onBussiness'].push(status);
            // }
            // if (status == '不確定') {
            //   tempArr[i]['notSure'].push(status);
            // }
            // if (status == '預查中') {
            //   tempArr[i]['survey'].push(status);
            // }
            // if (status == '非營業中') {
            //   tempArr[i]['closed'].push(status);
            // }
          }
        }
      }

      // 部會logo 圖片
      for (let i = 0; i < tempArr.length; i++) {
        for (let j = 0; j < this.ministryLogo.length; j++) {
          if (tempArr[i].name == this.ministryLogo[j].ministry) {
            tempArr[i].file = this.ministryLogo[j].imgLink;
          }
        }
      }

      // -----------------------
      // 最後輸出資料(操場)

      if (this.stadiumMenu == '公司數量') {
        tempArr.sort(function(a, b) {
          return b.company.length - a.company.length;
        });
        this.stadiumData = tempArr.map((el, i, arr) => {
          return {
            name: el.name,
            file: el.file,
            track: i + 1,
            value: el.company.length,
            bussinessStatus: el.bussinessType,
            nameArr: el.company,
            onBussiness: el.onBussiness.length,
            notSure: el.notSure.length,
            survey: el.survey.length,
            closed: el.closed.length
          };
        });

        // 計算樣本數
        let totalCompanyNumArr = []; // 篩選出的所有部會公司數量
        tempArr.forEach((el, i, arr) => {
          totalCompanyNumArr.push(el.company.length);
        });
        this.stadiumSample = totalCompanyNumArr.reduce(function(
          accumulator,
          currentValue
        ) {
          return accumulator + currentValue;
        },
        0);
      } else {
        tempArr.sort(function(a, b) {
          return a.sum - b.sum;
        });

        this.stadiumData = tempArr.map((el, i, arr) => {
          return {
            name: el.name,
            file: el.file,
            track: i + 1,
            value: el.sum
          };
        });

        // 計算樣本數
        let totalMoneyNumArr = []; // 篩選出的所有加的金額筆數
        tempArr.forEach((el, i, arr) => {
          totalMoneyNumArr.push(el.moneyArr.length);
        });
        this.stadiumSample = totalMoneyNumArr.reduce(function(
          accumulator,
          currentValue
        ) {
          return accumulator + currentValue;
        },
        0);
      }

      // -----------------
      // 柱狀圖最後輸出資料

      // 部會陣列
      let ministryFinalArr = tempArr.map(item => item.name);
      let ministryFinalSet = Array.from(new Set(ministryFinalArr)); // set: 找出重複的值
      // 刪掉部會是空值的
      for (let i = 0; i < ministryFinalSet.length; i++) {
        if (ministryFinalSet[i] == '') {
          ministryFinalSet.splice(i, 1);
        }
      }

      // 公司數量陣列
      let companyNumArr = [];

      tempArr.forEach(el => {
        companyNumArr.push(el.company.length);
      });

      this.ministryNumChart.xAxis[0].data = ministryFinalSet;
      this.ministryNumChart.yAxis[0].data = companyNumArr;
      this.ministryNumChart.series[0].data = this.stadiumData;

      this.stadiumSample = this.toCurrency(this.stadiumSample);
    },

    // ======================

    filterUnicorn({ project, classify, startTime, endTime }) {
      this.unicornInfo.projectFilter = project;
      this.unicornInfo.classifyFilter = classify;
      this.unicornInfo.startTime = startTime;
      this.unicornInfo.endTime = endTime;
    },

    formatToPrice(value) {
      return `${Number(value).toFixed(0)}`;
    },

    formatToCompanyNum(value) {
      return `${this.toCurrency(Number(value).toFixed(0))}`;
    },

    setTotalAmount() {
      // 存放一樣類型的金額加總
      let moneyObj = {};

      // 存放一樣類型的公司家數
      let companyNumObj = {};

      // 該公司是否已經計算過的判斷 金額
      let companyMoneyHasAlreadyInObj = {};

      // 該公司是否已經計算過的判斷家數
      let companyNumHasAlreadyInObj = {};

      let data = this.companyData;

      // 要是計畫篩選有值
      if (this.unicornInfo.projectFilter.length !== 0) {
        let projectFilterArr = [];
        this.unicornInfo.projectFilter.map(item => {
          projectFilterArr.push(
            ..._.filter(this.companyData, { forFilter: item })
          );
        });
        data = projectFilterArr;
      }

      // 要是有篩選時間
      if (this.unicornInfo.startTime || this.unicornInfo.endTime) {
        let timeArr = [];
        data.map((item, index) => {
          if (
            moment(this.unicornInfo.startTime, 'YYYY/MM/DD') <=
              moment(item.established_time, 'YYYY') &&
            moment(this.unicornInfo.endTime, 'YYYY/MM/DD') >=
              moment(item.established_time, 'YYYY')
          ) {
            timeArr.push(item);
          }
        });
        data = timeArr;
      }

      data.map((item, index) => {
        // this.projectInfo.classifyFilter 預設用type 產業別
        // 使用 'ALL' 代替 item[this.unicornInfo.classifyFilter]
        if (!_.get(moneyObj, 'ALL')) {
          moneyObj['ALL'] = 0;
        }
        if (!_.get(companyNumObj, 'ALL')) {
          companyNumObj['ALL'] = 0;
        }

        //要是已經有這家公司了 則不要在運算 金錢
        if (
          !_.get(
            companyMoneyHasAlreadyInObj,
            item['company'] +
              'ALL' +
              item['project_name'] +
              item['fundraising_stage'] +
              item['fundraising']
          )
        ) {
          if (!Number.isNaN(parseInt(item.fundraising))) {
            moneyObj['ALL'] += parseInt(item.fundraising);
          }
          companyMoneyHasAlreadyInObj[
            item['company'] +
              'ALL' +
              item['project_name'] +
              item['fundraising_stage'] +
              item['fundraising']
          ] = true;
        }

        // 要是已經有這家公司了 則不要在運算 家數
        if (!_.get(companyNumHasAlreadyInObj, item['company'])) {
          companyNumObj['ALL'] += 1;
          companyNumHasAlreadyInObj[item['company']] = true;
        }
      });

      // 募資金額
      let newArr = [];

      // 公司數量
      let newArrNumber = [];

      for (let item in moneyObj) {
        newArr.push({ name: item, value: moneyObj[item] });
      }

      for (let item in companyNumObj) {
        newArrNumber.push({ name: item, value: companyNumObj[item] });
      }

      //  篩選完後 總共多少錢
      let newArrJustNum = [];
      newArr.map(item => {
        newArrJustNum.push(item.value);
      });
      this.bigInfo.totalFund =
        newArrJustNum.length > 0
          ? Math.floor(
              newArrJustNum.reduce((accumulator, currentValue) => {
                return accumulator + currentValue;
              }) / 100000000
            )
          : 0;

      // 篩選完後 總共幾家
      let newArrNumberJustNum = [];
      newArrNumber.map(item => {
        newArrNumberJustNum.push(item.value);
      });

      this.bigInfo.companyNum =
        newArrNumberJustNum.length > 0
          ? newArrNumberJustNum.reduce((accumulator, currentValue) => {
              return accumulator + currentValue;
            })
          : 0;
    },

    // 各產業團隊募資金額(百萬新台幣)  團隊技術應用分佈（家） 資料運算
    setFundData() {
      // 存放一樣類型的金額加總
      let moneyObj = {};

      // 存放一樣類型的金額加總 的 樣本數用
      let moneyObjNum = {};

      // 存放一樣類型的公司家數
      let companyNumObj = {};

      // 該公司是否已經計算過的判斷 金額
      let companyMoneyHasAlreadyInObj = {};

      // 該公司是否已經計算過的判斷家數
      let companyNumHasAlreadyInObj = {};

      let data = this.companyData;

      // 要是計畫篩選有值
      if (this.unicornInfo.projectFilter.length !== 0) {
        let projectFilterArr = [];
        this.unicornInfo.projectFilter.map(item => {
          projectFilterArr.push(
            ..._.filter(this.companyData, { forFilter: item })
          );
        });
        data = projectFilterArr;
      }

      // 要是有篩選時間
      if (this.unicornInfo.startTime || this.unicornInfo.endTime) {
        let timeArr = [];
        data.map((item, index) => {
          if (
            moment(this.unicornInfo.startTime, 'YYYY/MM/DD') <=
              moment(item.established_time, 'YYYY') &&
            moment(this.unicornInfo.endTime, 'YYYY/MM/DD') >=
              moment(item.established_time, 'YYYY')
          ) {
            timeArr.push(item);
          }
        });
        data = timeArr;
      }

      data.map((item, index) => {
        // this.projectInfo.classifyFilter 預設用 type 產業別
        // 使用 'tech' 代替 this.projectInfo.classifyFilter
        if (item['tech']) {
          let tags = item['tech'].split(', ');

          tags.forEach(tag => {
            if (!_.get(moneyObj, tag)) {
              moneyObj[tag] = { money: 0, nameArr: [] };
              moneyObjNum[tag] = 0;
            }
            if (!_.get(companyNumObj, tag)) {
              companyNumObj[tag] = { num: 0, nameArr: [] };
            }

            //要是已經有這家公司了 則不要在運算 金錢
            if (
              !_.get(
                companyMoneyHasAlreadyInObj,
                item['company'] +
                  tag +
                  item['project_name'] +
                  item['fundraising_stage'] +
                  item['fundraising']
              )
            ) {
              if (!Number.isNaN(parseInt(item.fundraising))) {
                moneyObj[tag]['money'] += parseInt(item.fundraising);
                moneyObj[tag]['nameArr'].push(item.company);
                moneyObjNum[tag] += 1;

                companyMoneyHasAlreadyInObj[
                  item['company'] +
                    tag +
                    item['project_name'] +
                    item['fundraising_stage'] +
                    item['fundraising']
                ] = true;
              }
            }

            // 要是已經有這家公司了 則不要在運算 家數
            if (!_.get(companyNumHasAlreadyInObj, item['company'] + tag)) {
              companyNumObj[tag]['num'] += 1;
              companyNumObj[tag]['nameArr'].push(item.company);
              companyNumHasAlreadyInObj[item['company'] + tag] = true;
            }
          });
        }
      });

      // 募資金額
      let newArr = [];

      // 募資金額 樣本數用
      let newArrSampleNum = [];

      // 公司數量
      let newArrNumber = [];

      for (let item in moneyObj) {
        // 要是 this.unicornInfo.classifyFilter (type,apply,tech)不是空值 才做運算
        if (item !== '') {
          newArr.push({
            name: item,
            value: moneyObj[item]['money'],
            nameArr: moneyObj[item]['nameArr']
          });
        }
      }

      // 募資金額 樣本數用
      for (let item in moneyObjNum) {
        // 要是 this.unicornInfo.classifyFilter (type,apply,tech)不是空值 才做運算
        if (item !== '') {
          newArrSampleNum.push({ name: item, value: moneyObjNum[item] });
        }
      }

      // 募資金額樣本數計算
      let totalArr = newArrSampleNum.map(item => {
        return item.value;
      });

      let totalValue = 0;
      if (totalArr.length !== 0) {
        totalValue = this.reduceArr(totalArr);
      }

      this.teamfundraisingSampleNum = this.toCurrency(totalValue);

      for (let item in companyNumObj) {
        // 要是 this.unicornInfo.classifyFilter (type,apply,tech)不是空值 才做運算
        if (item !== '') {
          newArrNumber.push({
            name: item,
            value: companyNumObj[item]['num'],
            nameArr: companyNumObj[item]['nameArr']
          });
        }
      }

      // 團隊家數 樣本數計算
      let totalTeamArr = newArrNumber.map(item => {
        return item.value;
      });

      let totalTeamValue = 0;
      if (totalTeamArr.length !== 0) {
        totalTeamValue = this.reduceArr(totalTeamArr);
      }

      newArrNumber.sort(function(a, b) {
        return b.value - a.value;
      });
      this.industryTeamSampleNum = this.toCurrency(totalTeamValue);

      this.moneyChart.series[0].data[0].children = newArr;
      this.numberChart.series[0].data[0].children =
        newArrNumber.length <= 20 ? newArrNumber : newArrNumber.slice(0, 20);

      // this.stadiumChart = newArr;
    },

    //部會提供團隊募資概況 資料運算
    setAnnualFunding() {
      let ministryArr = [];

      // 該公司是否已經計算過的判斷 家數
      let companyNumHasAlreadyInObj = {};

      // 該公司是否已經計算過的判斷 金錢
      let companyMoneyHasAlreadyInObj = {};

      let data = this.companyData;

      // 要是計畫篩選有值
      if (this.unicornInfo.projectFilter.length !== 0) {
        let fundingFilterArr = [];
        this.unicornInfo.projectFilter.map(item => {
          fundingFilterArr.push(
            ..._.filter(this.companyData, { forFilter: item })
          );
        });
        data = fundingFilterArr;
      }

      // 要是有篩選時間
      if (this.unicornInfo.startTime || this.unicornInfo.endTime) {
        let timeArr = [];
        data.map((item, index) => {
          if (
            moment(this.unicornInfo.startTime, 'YYYY/MM/DD') <=
              moment(item.established_time, 'YYYY') &&
            moment(this.unicornInfo.endTime, 'YYYY/MM/DD') >=
              moment(item.established_time, 'YYYY')
          ) {
            timeArr.push(item);
          }
        });

        data = timeArr;
      }

      data.map((item, index) => {
        // 在空陣列中,建立 主管部會 是一樣的
        let itemMinistry = item['ministry'];
        // let itemTime = item['established_time'];
        let itemTime = this.unicornInfo.startTime;
        let itemEndTime = this.unicornInfo.endTime;

        if (ministryArr.findIndex(ele => ele.type == itemMinistry) == -1) {
          ministryArr.push({
            fund: 0,
            companyNum: 0,
            money: 0,
            type: itemMinistry,
            time: itemTime,
            endTime: itemEndTime,
            nameArr: []
          });
        }
      });

      let totalArr = [];

      data.forEach((item, index) => {
        // 該項目 主管部會
        let itemMinistry = item['ministry'];

        // 這家公司是否已經被計算到該筆計畫之中
        if (!_.get(companyNumHasAlreadyInObj, item['company'] + itemMinistry)) {
          for (let i of ministryArr) {
            if (itemMinistry == i.type) {
              i.companyNum += 1;
              i.nameArr.push(item.company);
            }
          }
          companyNumHasAlreadyInObj[item['company'] + itemMinistry] = true;
        }

        // 該公司 的 一筆計畫 的 募資階段 是否已經計算過的判斷
        // if (
        //   !_.get(
        //     companyMoneyHasAlreadyInObj,
        //     item['company'] + item['project_name'] + item['fundraising_stage']
        //   )
        // ) {

        totalArr = ministryArr.map(item => {
          return item.companyNum;
        });

        for (let i of ministryArr) {
          if (itemMinistry == i.type) {
            if (!Number.isNaN(parseInt(item.fundraising))) {
              i.money += parseInt(item.fundraising);
              i.fund += parseInt(item.fundraising);
            }
            // i.companyNum += 1;
          }
        }
        // 把該公司 的 一筆計畫 的 募資階段 加到已經計算過的陣列
        //   companyMoneyHasAlreadyInObj[
        //     item['company'] + item['project_name'] + item['fundraising_stage']
        //   ] = true;
        // }
      });
      let totalValue = 0;
      if (totalArr.length !== 0) {
        totalValue = this.reduceArr(totalArr);
      }
      this.assistTeamSampleNum = this.toCurrency(totalValue);

      let newMinistryArr = [];

      newMinistryArr = ministryArr.filter(item => item.type !== '');
      let lastArrData = newMinistryArr.map((item, index) => {
        let finalData = Object.values(item);
        let colorArr = [
          'rgb(152, 93, 0)',
          'rgb(234, 147, 8)',
          'rgb(0, 177, 178)',
          'rgb(0, 85, 86)'
        ];
        return {
          name: parseInt(finalData[3]),
          data: [finalData],
          type: 'scatter',
          symbolSize(data) {
            return Math.sqrt(data[2]) * 0.001;
          },
          label: {
            show: true,
            formatter({ data }) {
              return data[3];
            },
            position: 'bottom',
            color: '#000',
            fontWeight: 'bolder',
            fontSize: '18'
          },
          itemStyle: {
            normal: {
              color: colorArr[index % 4]
            }
          }
        };
      });
      this.planChart.series = lastArrData;
    },

    //  團隊所在地區（境內團隊家數） 資料運算
    setMap() {
      let vm = this;
      // 存放一樣縣市的公司家數
      let companyNumObj = {};

      // 該公司是否已經計算過的判斷
      let companyNumHasAlreadyInObj = {};

      let data = this.companyData;

      // 要是計畫篩選有值
      if (this.unicornInfo.projectFilter.length !== 0) {
        let fundingFilterArr = [];
        this.unicornInfo.projectFilter.map(item => {
          fundingFilterArr.push(
            ..._.filter(this.companyData, { forFilter: item })
          );
        });

        data = fundingFilterArr;
      }

      // 要是有篩選時間
      if (this.unicornInfo.startTime || this.unicornInfo.endTime) {
        let timeArr = [];
        data.map((item, index) => {
          if (
            moment(this.unicornInfo.startTime, 'YYYY/MM/DD') <=
              moment(item.established_time, 'YYYY') &&
            moment(this.unicornInfo.endTime, 'YYYY/MM/DD') >=
              moment(item.established_time, 'YYYY')
          ) {
            timeArr.push(item);
          }
        });

        data = timeArr;
      }

      data.map((item, index) => {
        // this.projectInfo.classifyFilter 預設用type 產業別
        if (!_.get(companyNumObj, item['city'])) {
          companyNumObj[item['city']] = { num: 0, nameArr: [] };
          // companyNumObj[item['city']]['num'] = 0;
          // companyNumObj[item['city']]['nameArr'] = [];
        }

        // 要是已經有這家公司了 則不要在運算 家數
        if (_.get(companyNumHasAlreadyInObj, item['company'] + item['city'])) {
          return;
        } else {
          // 要是城市名稱不為空白 把該公司加進城市的物件陣列中
          if (item.company) {
            companyNumObj[item['city']]['num'] += 1;
            companyNumObj[item['city']]['nameArr'].push(item.company);
          }
          companyNumHasAlreadyInObj[item['company'] + item['city']] = true;
        }
      });

      let areaData = [];

      for (let i in companyNumObj) {
        areaData.push({
          name: i,
          value: companyNumObj[i]['num'],
          nameArr: companyNumObj[i]['nameArr']
        });
      }

      areaData = areaData.filter(item => {
        return item.name !== '';
      });

      let totalArr = [];
      totalArr = areaData.map(item => {
        return item.value;
      });
      // totalArr有東西才進reduce
      let sampleNum = 0;
      if (totalArr.length !== 0) {
        sampleNum = vm.reduceArr(totalArr);
      }

      this.mapSampleNum = this.toCurrency(sampleNum);

      function convertData(data) {
        const res = [];
        for (let i = 0; i < data.length; i++) {
          const geoCoord = vm.geoCoordMap[data[i].name];
          if (geoCoord) {
            res.push({
              name: data[i].name,
              value: geoCoord.concat(data[i].value),
              nameArr: data[i]['nameArr']
            });
          }
        }
        return res;
      }

      let finalData = [];

      finalData = [
        {
          type: 'map',
          zoom: 1.2,
          aspectScale: 1,
          showLegendSymbol: false,
          center: [120.8098, 23.79108],
          tooltip: {
            extraCssText: 'z-index:500;',
            trigger: 'item',
            formatter: function(params) {
              if (!Number.isNaN(params.value)) {
                return (
                  params.name + '<br/> ' + vm.toCurrency(params.value) + '家'
                );
              } else {
                return params.name + '<br/> ' + 0 + '家';
              }
            }
          },
          map: 'taiwan',
          itemStyle: {
            areaColor: '#ffffff'
          },
          emphasis: {
            label: {
              show: false
            },
            itemStyle: {
              areaColor: '#BFEBEC'
            }
          },
          data: areaData
        },
        {
          name: '新創公司家數',
          type: 'scatter',
          coordinateSystem: 'geo',
          data: convertData(areaData),
          symbolSize: val => val[2] / 15,
          tooltip: {
            extraCssText: 'z-index:500;',
            formatter: function(val) {
              return val.name + '<br/> ' + vm.toCurrency(val.value[2]) + '家';
            }
          },
          itemStyle: {
            normal: {
              color: '#00B1B2'
            }
          }
        },
        {
          name: 'Top 5',
          type: 'effectScatter',
          coordinateSystem: 'geo',
          data: convertData(
            areaData.sort((a, b) => b.value - a.value).slice(0, 6)
          ),
          symbolSize: val => val[2] / 15,
          showEffectOn: 'render',
          rippleEffect: {
            brushType: 'stroke'
          },
          hoverAnimation: true,
          tooltip: {
            formatter: function(val) {
              return val.name + '<br/> ' + vm.toCurrency(val.value[2]) + '家';
            }
          },
          label: {
            normal: {
              formatter: '{b}',
              position: 'right',
              show: true
            }
          },
          itemStyle: {
            normal: {
              color: '#00B1B2',
              shadowBlur: 10,
              shadowColor: '#00B1B2'
            }
          },
          zlevel: 1
        }
      ];

      this.map.series = finalData;
    },

    // 團隊募資階段 資料運算
    setFundRasingStage() {
      // 存放一樣募資階段的金額加總
      let moneyObj = {};

      // 存放一樣募資階段的公司家數
      let companyNumObj = {};

      // 該公司是否已經計算過的判斷 金錢
      let companyMoneyHasAlreadyInObj = {};

      let data = this.companyData;

      // 要是計畫篩選有值
      if (this.unicornInfo.projectFilter.length !== 0) {
        let projectFilterArr = [];
        this.unicornInfo.projectFilter.map(item => {
          projectFilterArr.push(
            ..._.filter(this.companyData, { forFilter: item })
          );
        });
        data = projectFilterArr;
      }

      // 要是有篩選時間
      if (this.unicornInfo.startTime || this.unicornInfo.endTime) {
        let timeArr = [];
        data.map((item, index) => {
          if (
            moment(this.unicornInfo.startTime, 'YYYY/MM/DD') <=
              moment(item.established_time, 'YYYY') &&
            moment(this.unicornInfo.endTime, 'YYYY/MM/DD') >=
              moment(item.established_time, 'YYYY')
          ) {
            timeArr.push(item);
          }
        });
        data = timeArr;
      }

      data.map((item, index) => {
        if (item['fundraising_stage']) {
          if (!_.get(moneyObj, item['fundraising_stage'])) {
            moneyObj[item['fundraising_stage']] = 0;
          }

          if (!_.get(companyNumObj, item['fundraising_stage'])) {
            companyNumObj[item['fundraising_stage']] = { num: 0, nameArr: [] };
          }

          companyNumObj[item['fundraising_stage']]['num'] += 1;
          companyNumObj[item['fundraising_stage']]['nameArr'].push(
            item.company
          );

          if (!Number.isNaN(parseInt(item.fundraising))) {
            moneyObj[item['fundraising_stage']] += parseInt(item.fundraising);
          }
        } else if (
          item['fundraising_stage'] === '' &&
          item['fundraising'] !== ''
        ) {
          // fundraising_stage 是空值時，視為“ Series Unknown ”輪次
          if (!_.get(moneyObj, 'Series Unknown')) {
            moneyObj['Series Unknown'] = 0;
          }

          if (!_.get(companyNumObj, 'Series Unknown')) {
            companyNumObj['Series Unknown'] = { num: 0, nameArr: [] };
          }

          companyNumObj['Series Unknown']['num'] += 1;
          companyNumObj['Series Unknown']['nameArr'].push(item.company);

          if (!Number.isNaN(parseInt(item.fundraising))) {
            moneyObj['Series Unknown'] += parseInt(item.fundraising);
          }
        }
      });

      // 整理成一組有obj組成的陣列 募資金額
      let newArr = [];

      // 整理成一組有obj組成的陣列 公司家數
      let newArrNum = [];

      // 過濾掉name是空值的物件
      let filterArr = [];

      // 過濾掉name是空值的物件 家數
      let filterArrNum = [];

      // y軸座標資料
      let yAxisTitle = [];

      // 橫條bar的數據
      let barData = [];

      for (let i in moneyObj) {
        newArr.push({ name: i, value: moneyObj[i] });
      }

      for (let i in companyNumObj) {
        // 傳進去的公司 移除重複
        let companyNameArrNoRepeat = [...new Set(companyNumObj[i]['nameArr'])];
        newArrNum.push({
          name: i,
          // companyNum: companyNumObj[i]['num'],
          companyNum: companyNameArrNoRepeat.length,
          companyNameArr: companyNameArrNoRepeat
        });
      }

      filterArr = newArr.filter(item => {
        return item.name !== '' || item.name !== null;
      });

      filterArrNum = newArrNum.filter(item => {
        return item.name !== '' || item.name !== null;
      });

      let totalArr = filterArrNum.map(item => {
        return item.companyNum;
      });

      let totalValue = 0;
      if (totalArr.length !== 0) {
        totalValue = this.reduceArr(totalArr);
      }
      this.fundraisingSampleNum = this.toCurrency(totalValue);

      let mergeArr = [];
      mergeArr = filterArr.map(item => {
        filterArrNum.find(el => {
          if (item.name == el.name) {
            item.companyNum = el.companyNum;
            item.companyNameArr = el.companyNameArr;
          }
        });
        return item;
        // return filterArrNum.forEach(el => (item.companyNum = el.companyNum));
      });
      const specifyArr = [
        'Others',
        'Series Unknown',
        'Acquired',
        'D',
        'C',
        'B',
        'Pre-B',
        'A+',
        'A',
        'Pre-A',
        'Angel',
        'Seed+',
        'Seed',
        'Pre-Seed'
      ];

      mergeArr.sort(function(a, b) {
        if (a.name > b.name) {
          return -1;
        }
        if (a.name < b.name) {
          return 1;
        }
        return 0;
      });

      const fixSortArr = [
        ...mergeArr.filter(item => !specifyArr.includes(item.name)),
        mergeArr.find(item => item.name == 'Others'),
        mergeArr.find(item => item.name == 'Series Unknown'),
        mergeArr.find(item => item.name == 'Acquired'),
        mergeArr.find(item => item.name == 'D'),
        mergeArr.find(item => item.name == 'C'),
        mergeArr.find(item => item.name == 'B'),
        mergeArr.find(item => item.name == 'Pre-B'),
        mergeArr.find(item => item.name == 'A+'),
        mergeArr.find(item => item.name == 'A'),
        mergeArr.find(item => item.name == 'Pre-A'),
        mergeArr.find(item => item.name == 'Angel'),
        mergeArr.find(item => item.name == 'Seed+'),
        mergeArr.find(item => item.name == 'Seed'),
        mergeArr.find(item => item.name == 'Pre-Seed')
      ];
      fixSortArr.forEach(item => {
        if (item) {
          yAxisTitle.push(item.name);
        }
      });

      fixSortArr.forEach(item => {
        if (item) {
          barData.push({
            value: item.companyNum,
            itemStyle: { color: '#00B1B2' },
            companyNum: item.companyNum,
            money: item.value,
            nameArr: item.companyNameArr
          });
        }
      });

      this.horizonBarChart.yAxis.data = yAxisTitle;
      this.horizonBarChart.series[0].data = barData;
    },

    // 團隊獲各計畫協助比例 資料運算
    setparticipateNum() {
      // 存放公司參與計畫的數量
      let projectObj = {};

      // 該公司是否已經計算過的判斷
      let hasAlreadyObj = {};

      let data = this.companyData;

      // 要是計畫篩選有值
      if (this.unicornInfo.projectFilter.length !== 0) {
        let projectFilterArr = [];
        this.unicornInfo.projectFilter.map(item => {
          projectFilterArr.push(
            ..._.filter(this.companyData, { forFilter: item })
          );
        });
        data = projectFilterArr;
      }

      // 要是有篩選時間
      if (this.unicornInfo.startTime || this.unicornInfo.endTime) {
        let timeArr = [];
        data.map((item, index) => {
          if (
            moment(this.unicornInfo.startTime, 'YYYY/MM/DD') <=
              moment(item.established_time, 'YYYY') &&
            moment(this.unicornInfo.endTime, 'YYYY/MM/DD') >=
              moment(item.established_time, 'YYYY')
          ) {
            timeArr.push(item);
          }
        });
        data = timeArr;
      }

      data.map((item, index) => {
        // 每間公司當做基底, 物件中每個key為公司名 ,num為公司數 初始值為0,nameArr為公司名稱
        if (!_.get(projectObj, item['project_name'])) {
          projectObj[item['project_name']] = {
            num: 0,
            nameArr: [],
            project_name: item['project_name']
          };
        }

        // 要是某間公司 已經參與過該計畫 則不要再該計畫參與數+1
        if (!_.get(hasAlreadyObj, item['company'] + item['project_name'])) {
          projectObj[item['project_name']]['num'] += 1;
          projectObj[item['project_name']]['nameArr'].push(item.company);
          hasAlreadyObj[item['company'] + item['project_name']] = true;
        }
      });
      let theValues = [];
      let projects = [];
      let percentsProjects = [];
      let tempValues = [];

      //  相同計畫下,公司數量與公司名稱陣列所組成的陣列
      theValues = Object.values(projectObj);

      // 取出公司數量,組成新的陣列
      // theValues = tempValues.map(item => item.num);

      // this.participateChart.xAxis[0].data = name;

      // 建立一個陣列 是所有的值 並且 不重複
      // let noRepeat = [...new Set(theValues)];
      // // 給它排序
      // noRepeat.sort((a, b) => {
      //   return b - a;
      // });

      // 由大到小排序
      theValues.sort((a, b) => {
        return b.num - a.num;
      });

      // 過濾掉名稱為空白的計畫
      let filterArr = theValues.filter(item => {
        return item.name !== '';
      });

      let names = filterArr.map(item => item.project_name);

      // 計算樣本數
      let totalValue = 0;
      tempValues = filterArr.map(item => item.num);
      if (filterArr.length !== 0) {
        totalValue = this.reduceArr(tempValues);
      }
      this.participatePlanSampleNum = this.toCurrency(totalValue);

      this.participateChart.xAxis[0].data = names;

      for (let i of filterArr) {
        projects.push({
          value: i.num,
          itemStyle: { color: '#00B1B2' },
          nameArr: i.nameArr
        });

        percentsProjects.push({
          value: ((i.num / this.bigInfo.companyNum) * 100).toFixed(2),
          nameArr: i.nameArr
        });
      }

      this.participateChart.series[0].data = projects;
      this.participateChart.series[1].data = percentsProjects;
    },

    // 公司成立時間（家） 資料運算
    setEstablishedTimeChart() {
      // 存放該年份有多少公司數量
      let yearObj = {};

      // 該公司是否已經計算過的判斷
      let hasAlreadyObj = {};

      let data = this.companyData;

      // 要是計畫篩選有值
      if (this.unicornInfo.projectFilter.length !== 0) {
        let projectFilterArr = [];
        this.unicornInfo.projectFilter.map(item => {
          projectFilterArr.push(
            ..._.filter(this.companyData, { forFilter: item })
          );
        });
        data = projectFilterArr;
      }

      // 要是有篩選時間
      if (this.unicornInfo.startTime || this.unicornInfo.endTime) {
        let timeArr = [];
        data.map((item, index) => {
          if (
            moment(this.unicornInfo.startTime, 'YYYY/MM/DD') <=
              moment(item.established_time, 'YYYY') &&
            moment(this.unicornInfo.endTime, 'YYYY/MM/DD') >=
              moment(item.established_time, 'YYYY')
          ) {
            timeArr.push(item);
          }
        });
        data = timeArr;
      }

      data.map((item, index) => {
        let itemYear = moment(item['established_time'], 'YYYY/MM/DD').format(
          'YYYY'
        );
        // 成立時間符合以下條件才帶入運算
        if (itemYear.length < 5 && itemYear < 2022 && itemYear > 2010) {
          if (!_.get(yearObj, itemYear)) {
            // 每個年份當做基底, 物件中每個key為年份 ,num為公司數 初始值為0
            yearObj[itemYear] = { num: 0, nameArr: [] };
          }

          // 要是已經有這家公司了 並且在該年份成立 則不要再把該公司在此年份+1
          if (!_.get(hasAlreadyObj, item['company'] + itemYear)) {
            yearObj[itemYear]['num'] += 1;
            yearObj[itemYear]['nameArr'].push(item.company);
            hasAlreadyObj[item['company'] + itemYear] = true;
          }
        }
      });

      let yearArr = Object.keys(yearObj);

      let companyNumArr = [];
      for (let i in yearObj) {
        companyNumArr.push(yearObj[i]['num']);
      }

      let companyNameArr = [];
      for (let i in yearObj) {
        companyNameArr.push(yearObj[i]['nameArr']);
      }

      let projectArr = [];
      for (let i in yearObj) {
        projectArr.push({
          value: yearObj[i]['num'],
          itemStyle: { color: '#00B1B2' },
          nameArr: yearObj[i]['nameArr']
        });
      }

      // 計算樣本數
      let totalValue = 0;
      if (companyNumArr.length !== 0) {
        totalValue = this.reduceArr(companyNumArr);
      }
      this.timeDistributionSampleNum = this.toCurrency(totalValue);

      this.establishedTimeChart.xAxis[0].data = yearArr;

      this.establishedTimeChart.series[0].data = projectArr;
    },

    // 團隊產業分佈 設定
    setHotPieChart() {
      let vm = this;
      am4core.useTheme(am4themes_moonrisekingdom);

      // let chart = am4core.create(
      //   this.$refs.hotPieChartdiv,
      //   am4charts.PieChart3D
      // );;

      let container = am4core.create(
        this.$refs.hotPieChartdiv,
        am4core.Container
      );
      // let container = mcontainer.create(am4core.Container);
      container.width = am4core.percent(100);
      container.height = am4core.percent(100);

      let chart = container.createChild(am4charts.PieChart3D);

      chart.width = am4core.percent(50);
      chart.align = 'center';

      chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

      //==================================================資料處理
      // 存放一樣類型的公司家數
      let companyNumObj = {};

      // 該公司是否已經計算過的判斷
      let companyNumHasAlreadyInObj = {};

      let data = this.companyData;

      // 要是計畫篩選有值
      if (this.unicornInfo.projectFilter.length !== 0) {
        let projectFilterArr = [];
        this.unicornInfo.projectFilter.map(item => {
          projectFilterArr.push(
            ..._.filter(this.companyData, { forFilter: item })
          );
        });

        data = projectFilterArr;
      }

      // 要是有篩選時間
      if (this.unicornInfo.startTime || this.unicornInfo.endTime) {
        let timeArr = [];
        data.map((item, index) => {
          if (
            moment(this.unicornInfo.startTime, 'YYYY/MM/DD') <=
              moment(item.established_time, 'YYYY') &&
            moment(this.unicornInfo.endTime, 'YYYY/MM/DD') >=
              moment(item.established_time, 'YYYY')
          ) {
            timeArr.push(item);
          }
        });

        data = timeArr;
      }

      // 圓餅圖 this.unicornInfo.classifyFilter 是預設的 type
      data.map((item, index) => {
        if (item[this.unicornInfo.classifyFilter]) {
          let tags = item[this.unicornInfo.classifyFilter].split(', ');

          tags.forEach(tag => {
            if (!_.get(companyNumObj, tag)) {
              companyNumObj[tag] = {
                num: 0,
                nameArr: []
              };
            }

            // 要是現在這個分類已經有這家公司了 則不要在運算
            if (!_.get(companyNumHasAlreadyInObj, item['company'] + tag)) {
              companyNumObj[tag]['num'] += 1;
              companyNumObj[tag]['nameArr'].push(item.company);
              // 把這個分類的這家公司 加入不要重複運算的companyNumHasAlreadyInObj當中
              companyNumHasAlreadyInObj[item['company'] + tag] = true;
            }
          });
        }
      });

      // 公司數量
      let newArrNumber = [];

      for (let item in companyNumObj) {
        newArrNumber.push({
          name: item,
          value: companyNumObj[item]['num'],
          nameArr: companyNumObj[item]['nameArr']
        });
      }
      // 只要前20筆
      let arrForChart = [];
      // 比較大小 拿出前面20筆
      arrForChart = newArrNumber.sort((a, b) => b.value - a.value).slice(0, 20);

      let totalArr = arrForChart.map(item => {
        return item['value'];
      });

      let totalValue = 0;

      if (totalArr.length !== 0) {
        totalValue = this.reduceArr(totalArr);
      }

      this.popularIndustrySampleNum = this.toCurrency(totalValue);

      //=========================================================資料處理

      // chart.legend = new am4charts.Legend();

      chart.data = arrForChart;

      var series = chart.series.push(new am4charts.PieSeries3D());
      series.dataFields.value = 'value';
      series.dataFields.category = 'name';

      chart.events.on('sizechanged', function(ev) {
        if (vm.windowWidth < 992) {
          series.labels.template.disabled = true;
          series.ticks.template.disabled = true;
        } else {
          series.labels.template.disabled = false;
          series.ticks.template.disabled = false;
        }
      });
      // if (this.windowWidth <= 992) {
      //   series.ticks.template.events.on('ready', ev => {
      //     ev.target.hide();
      //   });
      //   series.labels.template.events.on('ready', ev => {
      //     ev.target.hide();
      //   });
      // }
      // series.ticks.template.events.on('ready', ev => {
      //   ev.target.hide();
      // });
      // series.labels.template.events.on('ready', ev => {
      //   ev.target.hide();
      // });

      series.slices.template.events.on('hit', function(ev) {
        let forModalCompanyData = {
          data: { nameArr: [] }
        };
        forModalCompanyData.data.nameArr =
          ev.target.dataItem.dataContext.nameArr;
        vm.handleSaveComapnyArray(forModalCompanyData);
      });
    },

    // 獲跨計畫協助團隊家數  設定
    setPlanAssistColumChart() {
      let vm = this;
      am4core.useTheme(am4themes_animated);
      let chart = am4core.create(
        this.$refs.planAssistColumChart,
        am4charts.XYChart
      );

      // //==================================================資料處理
      // 存放公司的參與計畫數
      let companyNumObj = {};
      // 存放計畫家數一樣的 公司數 key:協助計畫數 value:所有的公司名稱
      let planNumObj = {};
      // 該公司是否已經計算過的判斷
      let planHasAlreadyInThisCompany = {};
      let data = this.companyData;
      // 要是計畫篩選有值
      if (this.unicornInfo.projectFilter.length !== 0) {
        let projectFilterArr = [];
        this.unicornInfo.projectFilter.map(item => {
          projectFilterArr.push(
            ..._.filter(this.companyData, { forFilter: item })
          );
        });
        data = projectFilterArr;
      }
      // 要是有篩選時間
      if (this.unicornInfo.startTime || this.unicornInfo.endTime) {
        let timeArr = [];
        data.map((item, index) => {
          if (
            moment(this.unicornInfo.startTime, 'YYYY/MM/DD') <=
              moment(item.established_time, 'YYYY') &&
            moment(this.unicornInfo.endTime, 'YYYY/MM/DD') >=
              moment(item.established_time, 'YYYY')
          ) {
            timeArr.push(item);
          }
        });
        data = timeArr;
      }
      data.map((item, index) => {
        if (!_.get(companyNumObj, item.company)) {
          companyNumObj[item.company] = 0;
        }
        // 要是現在這個公司已經有這個計畫了 則不要在運算
        if (
          !_.get(
            planHasAlreadyInThisCompany,
            item['company'] + item['project_name']
          )
        ) {
          companyNumObj[item.company] += 1;
          // 把這個分類的這家公司 加入不要重複運算的planHasAlreadyInThisCompany當中
          planHasAlreadyInThisCompany[
            item['company'] + item['project_name']
          ] = true;
        }
      });
      let tempArr = [];
      // 把所有公司的參與計畫數量拿出來變成一個陣列
      for (let i in companyNumObj) {
        tempArr.push(companyNumObj[i]);
      }
      // 所有公司總長度
      let totalLength = tempArr.length;
      // 移除該陣列重複的值
      let projectNumArr = [...new Set(tempArr)];
      // 把該陣列由大到小排序
      projectNumArr.sort(function(a, b) {
        return b - a;
      });
      // 該陣列的值逐筆 丟進 planNumObj 當成key
      projectNumArr.map(item => {
        if (!_.get(planNumObj, item)) {
          planNumObj[item] = { nameArr: [], total: 0 };
        }
      });
      // 每家公司參與計畫數的物件 逐筆丟進planNumObj中 key值為該參與計畫數的陣列
      for (let i in companyNumObj) {
        // console.log(companyNumObj[i]);
        planNumObj[companyNumObj[i]].nameArr.push(i);
        planNumObj[companyNumObj[i]].total += 1;
      }
      let dataForChart = [];

      this.planAssistSampleNum = totalLength;
      for (let i in planNumObj) {
        let percentValue = ((planNumObj[i].total / totalLength) * 100).toFixed(
          1
        );
        dataForChart.push({
          participateNum: i,
          percent: +percentValue,
          nameArr: planNumObj[i].nameArr
        });
      }
      chart.data = dataForChart;
      // =========================================================
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.dataFields.category = 'participateNum';
      categoryAxis.renderer.minGridDistance = 60;
      categoryAxis.fontSize = 11;
      categoryAxis.title.text = '協助計畫次數';
      categoryAxis.title.fontSize = 16;
      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.max = 100;
      valueAxis.strictMinMax = true;
      valueAxis.renderer.minGridDistance = 60;
      valueAxis.title.text = '占比(百分比)';
      valueAxis.title.rotation = 0;
      valueAxis.title.align = 'center';
      valueAxis.title.valign = 'top';
      valueAxis.title.dx = 40;
      valueAxis.title.dy = -45;

      // axis break
      var axisBreak = valueAxis.axisBreaks.create();
      axisBreak.startValue = 10;
      axisBreak.endValue = 70;
      //axisBreak.breakSize = 0.005;
      // fixed axis break
      var d =
        (axisBreak.endValue - axisBreak.startValue) /
        (valueAxis.max - valueAxis.min);
      axisBreak.breakSize = (0.05 * (1 - d)) / d; // 0.05 means that the break will take 5% of the total value axis height
      // make break expand on hover
      var hoverState = axisBreak.states.create('hover');
      hoverState.properties.breakSize = 1;
      hoverState.properties.opacity = 0.1;
      hoverState.transitionDuration = 1500;
      axisBreak.defaultState.transitionDuration = 1000;

      var series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.categoryX = 'participateNum';
      series.dataFields.valueY = 'percent';
      series.columns.template.tooltipText = '公司家數佔比:{valueY.value}%';
      series.columns.template.tooltipY = 0;
      series.columns.template.strokeOpacity = 0;
      // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
      series.columns.template.adapter.add('fill', function(fill, target) {
        return chart.colors.getIndex(target.dataItem.index);
      });

      // 圖表點擊 展開公司列表
      series.columns.template.events.on('hit', function(ev) {
        let forModalCompanyData = {
          data: { nameArr: [] }
        };
        forModalCompanyData.data.nameArr =
          ev.target.dataItem.dataContext.nameArr;
        vm.handleSaveComapnyArray(forModalCompanyData);
      });

      // 圖表scrollbar
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarY = new am4core.Scrollbar();
      // //==================================================資料處理
    },

    // 拿到所有的政府部門
    setMinistryArr(callback) {
      let arr = [];
      this.companyData.forEach((item, index) => {
        if (_.indexOf(arr, item.ministry) == -1 && item.ministry) {
          arr.push(item.ministry);
        }
      });
      this.ministryArr = arr;
      callback();
    },

    // 拿到於同個政府部門下的計畫
    setProjectArr() {
      let obj = {};

      // 用剛剛拿到的政府部門
      this.ministryArr.map((item, index) => {
        obj[item] = [];
      });

      this.companyData.map((item, index) => {
        if (
          _.indexOf(obj[item.ministry], item.forFilter) == -1 &&
          item.ministry
        ) {
          obj[item.ministry].push(item.forFilter);
        }
      });
      this.projectObj = obj;
    }
  }
};
</script>

<style lang="scss" scoped>
div.tooltip {
  width: unset;
  height: unset;
}
</style>
