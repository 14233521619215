import axios from 'axios';
import _ from 'lodash';
import Vue from 'vue';
import store from '@/store';
import router from '@/router';
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

// Init plugin
Vue.use(Loading, { loader: 'bars', color: '#00B1B2', height: 128, width: 128 });

const baseURL = process.env.VUE_APP_API_FASTAPI_URL;

let apiStatus = {};

let loadingCount = 0;

let loader;

const toggleLoading = (display, displayLoading) => {
  if (!displayLoading) return;

  if (display) {
    loadingCount++;
  } else {
    loadingCount--;
  }

  if (loadingCount > 0) {
    if (!loader) {
      loader = Vue.$loading.show();
    }
  } else {
    loadingCount = 0;
    if (loader === undefined) {
      return;
    }
    loader.hide();
    loader = undefined;
  }
};

export const debounceErrorAlert = _.debounce(() => {
  alert('伺服器連線異常');
}, 500);

export default ({
  method,
  url,
  config = {},
  cb,
  errCb,
  displayLoading = true,
  canRepeat = false
}) => {
  let isStillGetting = _.get(apiStatus, url, false);

  if (isStillGetting && !canRepeat) {
    return;
  }

  _.set(apiStatus, url, true);

  let defaultConfig = {
    // headers: {
    //   Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
    // },
    baseURL,
    method,
    url,
    timeout: 20000
    // params: {
    //   APIKey: process.env.VUE_APP_RAGIC_TOKEN,
    //   limit: '0,0'
    // }
    // ...authorization()
  };

  let usingConfig = Object.assign(defaultConfig, config);

  toggleLoading(true, displayLoading);

  axios(usingConfig)
    .then(response => {
      _.unset(apiStatus, url);

      toggleLoading(false, displayLoading);

      // console.log(response);

      // const code = _.get(response, 'data.Outstring');

      const data = _.get(response, 'data');

      // const message = _.get(response, 'data.Message');

      if (cb) {
        cb(data);
      }

      // if (code == '0000') {
      //   if (cb) {
      //     cb(data);
      //   }
      // } else {
      //   if (errCb) {
      //     errCb(message);
      //   } else {
      //     alert(message);
      //   }
      // }
    })
    .catch(error => {
      _.unset(apiStatus, url);

      toggleLoading(false, displayLoading);

      // eslint-disable-next-line no-console
      console.info(error);

      const status = _.get(error, 'response.status');

      const code = _.get(error, 'response.data.status.code');

      // if (status == 401) {
      //   store.dispatch('user/logout');
      //   return;
      // }

      const message = _.get(error, 'response.data.status.message');

      if (errCb) {
        errCb(message, error);

        return;
      }

      if (message) {
        alert(message);
        return;
      }

      debounceErrorAlert();
    });
};
